<template>
  <div>
    <b-card-actions
        ref="first-card"
        class="list-card big-table"
        @refresh="refreshStop('first-card')"
    >
      <b-row class="mt-50 mb-5">
        <div class="card-header-row" :style="{backgroundColor: $store.state.colors.headerRowColor}">
          <b-col cols="12" class="d-flex justify-content-between">
            <h4 class="text-white d-inline-block card-title">{{ title }}</h4>
          </b-col>
        </div>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-table
              striped
              hover
              responsive
              class="position-relative vardiya-list-table mt-1"
              :current-page="currentPage"
              :items="vardiyaList"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              @row-clicked="goDetay"
          >
            <template #cell(vardiyaID)="data">
              <b-dropdown
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  no-caret
                  class="bg-danger bg-light-2"
              >
                <template #button-content>
                  <font-awesome-icon icon="bars"/>
                </template>
                <b-dropdown-item variant="success" :to="`/detayliTablo/${data.item.vardiyaID}`">
                  Detaylı Gelir Listesi
                </b-dropdown-item>
                <b-dropdown-item variant="warning" :to="`/giderListesi/${data.item.vardiyaID}`">
                  Gider Listesi
                </b-dropdown-item>
                <b-dropdown-item variant="primary" :to="`/adisyonListesi/${data.item.vardiyaID}`">
                  Adisyon Listesi
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template #cell(baslangic)="data">
              <p class="m-0 font-weight-bolder ">
                {{ data.item.baslangic.slice(0, 16) }}</p>
            </template>
            <template #cell(bitis)="data">
              <p class="m-0 font-weight-bolder" :class="{'text-success': !data.item.bitis}">
                {{ data.item.bitis.slice(0, 16) }}</p>
            </template>
            <template #cell(konukSayisi)="data">
              <b-badge variant="light-success">
                {{ data.item.konukSayisi || 0 }}
              </b-badge>
            </template>
            <template #cell(ciro)="data">
              <p class="d-inline-block m-0 font-weight-bolder mr-50">{{ data.item.ciro || 0 }}</p>
              <font-awesome-icon icon="turkish-lira-sign"/>
            </template>
            <template #cell(krediKarti)="data">
              <p class="d-inline-block m-0 font-weight-bolder mr-50">{{ data.item.krediKarti || 0 }}</p>
              <font-awesome-icon icon="turkish-lira-sign"/>
            </template>
            <template #cell(nakit)="data">
              <p class="d-inline-block m-0 font-weight-bolder mr-50">{{ data.item.nakit || 0 }}</p>
              <font-awesome-icon icon="turkish-lira-sign"/>
            </template>
            <template #cell(tahsilat)="data">
              <p class="d-inline-block m-0 font-weight-bolder mr-50">{{ data.item.tahsilat || 0 }}</p>
              <font-awesome-icon icon="turkish-lira-sign"/>
            </template>
            <template #cell(cariKredi)="data">
              <p class="d-inline-block m-0 font-weight-bolder mr-50">{{ data.item.cariKredi || 0 }}</p>
              <font-awesome-icon icon="turkish-lira-sign"/>
            </template>
            <template #cell(tahsilat)="data">
              <p class="d-inline-block m-0 font-weight-bolder mr-50">{{ data.item.tahsilat || 0 }}</p>
              <font-awesome-icon icon="turkish-lira-sign"/>
            </template>
            <template #cell(gider)="data">
              <p class="d-inline-block m-0 font-weight-bolder mr-50">{{ data.item.gider || 0 }}</p>
              <font-awesome-icon icon="turkish-lira-sign"/>
            </template>
          </b-table>
          <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              first-number
              last-number
              size="sm"
              class="my-0"
              @input="pageChange($event)"
          />
        </b-col>
      </b-row>
    </b-card-actions>
    <app-collapse type="border" class="small-collapse">
      <app-collapse-item v-for="item in vardiyaList" :key="item.vardiyaID" :vardiya="item"
                         title="Vardiya"
      >
        <app-timeline>
          <app-timeline-item
              :title="item.konukSayisi || ''"
              subtitle="Konuk Sayısı"
              icon="users"
              variant="success"
          />
          <app-timeline-item
              :title="item.ciro + ' TL'"
              subtitle="Ciro"
              icon="arrow-trend-up"
              variant="info"
          />
          <app-timeline-item
              :title="item.nakit"
              subtitle="Nakit Gelir"
              icon="arrow-trend-up"
              variant="warning"
          />
          <app-timeline-item
              :title="item.krediKarti"
              subtitle="Kredi Kartı"
              icon="arrow-trend-up"
          />
          <app-timeline-item
              :title="item.cariKredi"
              subtitle="Cari Kredi"
              icon="money-bills"
              variant="success"
          />
          <app-timeline-item
              :title="item.tahsilat"
              subtitle="Tahsilat"
              icon="arrow-trend-up"
              variant="success"
          />
          <app-timeline-item
              :title="item.gider"
              subtitle="Gider"
              icon="arrow-trend-down"
              variant="danger"
          />
        </app-timeline>
      </app-collapse-item>
    </app-collapse>
  </div>
</template>
<script>
import {
  BDropdown,
  BDropdownItem,
  BPagination,
  BTable,
  BRow,
  BCol,
  VBTooltip,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import store from '@/store'
import axios from 'axios'
import router from '@/router'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    AppCollapse,
    AppCollapseItem,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTable,
    BBadge,
    BCardActions,
    BRow,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      fields: [
        {
          key: 'vardiyaID',
          label: '',
          sortable: true,
          thClass: ['text-left'],
          tdClass: ['text-left'],
          thStyle: {
            width: '5%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '5%' },
        },
        {
          key: 'baslangic',
          label: 'Başlangıç',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '15%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },
        },
        {
          key: 'bitis',
          label: 'Bitiş',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '15%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },
        },
        {
          key: 'konukSayisi',
          label: 'Konuk',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '5%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '5%' },
        },
        {
          key: 'ciro',
          label: 'Ciro',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },

        },
        {
          key: 'nakit',
          label: 'Nakit Gelir',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },

        },
        {
          key: 'krediKarti',
          label: 'Kredi Kartı',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },

        },
        {
          key: 'cariKredi',
          label: 'Cari Kredi',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },

        },
        {
          key: 'tahsilat',
          label: 'Tahsilat',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },

        },
        {
          key: 'gider',
          label: 'Gider',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },

        },
      ],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
    }
  },
  computed: {
    title() {
      return 'Vardiya Listesi'
    },
    vardiyaList: () => store.getters.GET_VARDIYA_LIST,
  },
  created() {
    this.vardiyaListData()
  },
  beforeDestroy() {
    store.commit({
      type: 'SET_VARDIYA_LIST',
      action: 0,
    })
  },
  methods: {
    goDetay(item) {
      router.push({
        name: 'vardiyaDetayi',
        params: { id: item.bitis ? item.vardiyaID : 0 },
      })
    },
    pageChange(event) {
      this.vardiyaListData(event)
    },
    vardiyaListData(currentPage = 1) {
      const fd = this.postSchema()
      fd.set('methodName', 'vardiyaListesi')
      fd.set('sayfa', currentPage)
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              this.totalRows = response.toplamKayit
              store.commit({
                type: 'SET_VARDIYA_LIST',
                action: 1,
                list: response.vardiyaList,
              })
            }
          })
    },
    postSchema() {
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken || localStorage.getItem('restoranUserToken'))
      fd.append('serviceName', 'vardiya')
      return fd
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'first-card'].showLoading = false
      }, 1000)
    },
  },
}
</script>
<style scoped>
.vardiya-list-table {
  min-height: 150px;
}

.vardiya-list-table .table td {
  padding: 0.22rem 1rem;
}

.small-collapse {
  display: none;
}

@media (max-width: 500px) {
  .big-table {
    display: none;
  }

  .small-collapse {
    display: inline;
  }
}
</style>
